// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$eshed-web-primary: mat.define-palette(mat.$indigo-palette);
$eshed-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$eshed-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$eshed-web-theme: mat.define-light-theme((
        color: (
                primary: $eshed-web-primary,
                accent: $eshed-web-accent,
                warn: $eshed-web-warn,
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($eshed-web-theme);

/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import 'ngx-toastr/toastr';

body {
  overflow-x: hidden;
}

/*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}*/
::-webkit-scrollbar-track {
  width: 1px;
  background: lightgray;
}

.content {
  padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}

div, p, span, label, input, textarea, button, table, tr, td {
  font-family: "Open Sans";
}

h1, h2, h3, h4, h5, h6, th {
  font-family: "Open Sans bold";
  color: black;
}

p {
  font-size: 14px !important;
}

h1 {
  font-size: 34px !important;
}

h2 {
  font-size: 32px !important;
}

h3 {
  font-size: 26px !important;
}

h4 {
  font-size: 22px !important;
}

h5 {
  font-size: 20px !important;
}

h6, span {
  font-size: 16px !important;
}

.span {
  font-size: 14px !important;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  background-color: #cacdce;
  color: black;
}

.mt-50 {
  margin-top: 50px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-150 {
  margin-top: 150px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-150 {
  margin-left: 150px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-150 {
  margin-right: 150px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-150 {
  margin-bottom: 150px;
}

.table-style {
  height: 400px;
  overflow-y: scroll;
}

.login {
  background: rgb(214, 255, 213);
  background: radial-gradient(circle, rgba(214, 255, 213, 1) 0%, rgba(207, 245, 207, 1) 12%, rgba(178, 247, 197, 1) 25%, rgba(165, 254, 180, 1) 100%);
}

.otp-image {
  max-width: 200px;
  max-height: 300px;
}

.nav-sidebar {
  overflow-x: hidden;
}

.nav-pills .nav-link {
  color: green;
  background-color: #c6f8c5;
  padding: 14px;
  margin-bottom: 5px;
}

.nav-pills .nav-link:not(.active):hover {
  background-color: #00550d;
  color: white;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: green !important;
  padding: 16px;
  color: white;
}

.nav-sidebar .nav-item > .nav-link {
  margin-bottom: 7px;
}

.main-header {
  border-bottom: none !important;
}

.content-wrapper{
  background-color: #F3F8EC !important;
}

.icon-circle {
  height: 40px;
  width: 40px;
  background-color: #359708;
  border-radius: 50%;
  display: inline-block;
}

.badge-active {
  border: 1px solid #CAC4D0;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #359708;
  font-size: 12px !important;
  margin-top: 2px !important;
}

.badge-inactive {
  border: 1px solid #B43A13;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #B43A13;
  font-size: 12px !important;
}

.btn-inactive {
  border: 1px solid #CAC4D0;
  border-radius: 25px;
  padding: 10px 27px;
  font-size: 12px !important;
}

.card-user-img {
  max-height: 250px;
}

.loader {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 5px solid #eef3f2;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 5px solid #ef6210;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 5px solid hsl(129, 86%, 48%);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@media only screen and (min-width: 1266px) {
  .example-card {
    border-radius: 12px !important;
    margin: 10px;
    width: 23% !important;
    background-color: #FEF7FF;
    border: 1px solid #d4d4d4;
    max-height: 50%;
  }
}
@media only screen and (min-width: 1081px) and (max-width: 1265px) {
  .example-card {
    border-radius: 12px !important;
    margin: 5px;
    width: 32% !important;
    background-color: #FEF7FF;
    border: 1px solid #d4d4d4;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1080px) {
  .example-card {
    border-radius: 12px !important;
    margin: 5px;
    width: 47% !important;
    background-color: #FEF7FF;
    border: 1px solid #d4d4d4;
  }
}

@media only screen and (max-width: 767px) {
  .example-card {
    border-radius: 12px !important;
    margin: 5px;
    width: 99% !important;
    background-color: #FEF7FF;
    border: 1px solid #d4d4d4;
  }
}

.card-bg-light {
  background-color: #FEF7FF;
  border: 1px solid #d4d4d4;
}

.bg-white-detail {
  background-color: #ffffff;
  border-radius: 5px;
  width: auto;
}

.btn-success-green {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 8px;
  padding: 14px 24px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  color: #fff;
  background-color: #359708;
}

.btn-danger-red {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 8px;
  padding: 14px 24px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  color: #fff;
  background-color: #970808;
}


.btn-secondary-light {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  border-radius: 8px;
  padding: 14px 24px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  color: #fff;
  background-color: #8A9884;
}

.badge-success-green {
  display: inline-block;
  outline: none;
  font-size: 16px !important;
  line-height: 20px;
  font-weight: 400;
  border-radius: 8px;
  padding: 18px 24px;
  border: none;
  text-align: center;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  color: #fff;
  background-color: #359708;
}

.badge-primary-blue {
  display: inline-block;
  outline: none;
  font-size: 16px !important;
  line-height: 20px;
  font-weight: 400;
  border-radius: 8px;
  padding: 18px 24px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  color: #fff;
  background-color: #538af5;
}

.example-header-image {
  /*background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');*/
  background-size: cover;
}

.mat-card-actions {
  margin-bottom: 1px !important;
}

.mat-card:not([class*=mat-elevation-z]) {
 /* box-shadow: 0px 0px !important;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
}

.bg-light-gray {
  background-color: #d9d9d9 !important;
}

/*
#countryCode {
  border-right: transparent !important;
}
*/
.mat-card-image {
  /*max-height: 32vh;*/
  height: 32vh;
}

.image-loading {
  background-color: hsl(220, 20%, 90%);
  /* Make background pulse so it looks kinda load-y */
  animation: loading 0.8s infinite alternate;
}

@keyframes loading {
  to {
    background-color: hsl(220, 10%, 75%);
  }
}


.logo-image {
  height: 100px;
  width: auto;
}

.order-card {
  border-radius: 12px !important;
  margin: 10px;
  background-color: #fff;
  border: 1px solid rgba(212, 212, 212, 0.4);
}

.table-green th {
  background-color: #c6f8c5 !important;
  color: #0c622e !important;
  text-align: center;
  padding: 12px !important;
}

.badge-gradient-green {
  box-shadow: #8af38a !important;
  color: #0c622e !important;
  background-color: #dcf1e4 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badge-gradient-blue {
  box-shadow: #8ae3f3 !important;
  color: rgb(8, 70, 130) !important;
  background-color: rgb(115, 188, 253) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badge-gradient-yellow {
  box-shadow: #ecf38a !important;
  color: #fd7e14 !important;
  background-color: rgb(245, 237, 166) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badge-gradient-red {
  box-shadow: #fae4e8 !important;
  color: #b03d50 !important;
  background-color: #fae4e8 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badge-gradient-secondary {
  box-shadow: #f1f2f3 !important;
  color: #404247 !important;
  background-color: #f1f2f3 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.badge-gradient-info {
  box-shadow: #e5f4f8 !important;
  color: #3b7e94 !important;
  background-color: #e5f4f8 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.nav-link>p>.right {
  position: absolute;
  right: 1rem;
  top: 1.3rem !important;
}

.modal-header-bg {
  background-image: linear-gradient(-225deg, #a3ee7a 0%, #6cf69b 100%);
  height: 100px;
  border-radius: 20px !important;
  color: #0c622e;
}

.items {
  flex-basis: 330px;  /* Adjust as needed for card width */
  gap: 5px;
  border-radius: 12px !important;
  background-color: white;
  border: 1px solid #d4d4d4;
  flex-grow: 0;
  max-width: 100%;
}

.content-card {
  display: flex;
  flex-wrap: wrap; /* Enable cards to wrap onto multiple lines */
  gap: 10px;
}

/* Responsive layout media queries */
@media (min-width: 1440px) {  /* Desktops & Laptops */
  .content-card {
    justify-content: start;
  }
  .items {
    flex-basis: calc(24%);
  }
}

@media (max-width: 1439px) and (min-width: 1024px) {  /* Laptops */
  .content-card {
    justify-content: start;
  }
  .items {
    flex-basis: calc(33% - 10px);
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .content-card {
    justify-content: start;
  }
  .items {
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 767px) {
  .content-card {
    justify-content: center;
  }
  .items {
    flex-basis: 100%;
  }
}

.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
  margin-top: calc(0.5rem + 1px) !important;
}

.error-gradient-red {
  color: #ff0028 !important;
  background-color: #fae4e8 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  /* outline-color: #f63251 !important; */
  width: 100%;
  padding: 10px !important;
  border: 1px solid #ff9a9a !important;
}

.user-product-image {
  height: 100% !important;
  width: 100%;
}
