@font-face {
    font-family: "Open Sans";
    font-weight: normal;
    font-style: normal;
    src: url(~assets/fonts/opensans/opensans_regular.ttf) format("truetype");
  }

@font-face {
  font-family: "Open Sans bold";
  font-weight: bold;
  font-style: normal;
  src: url(~assets/fonts/opensans/opensans_bold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans semibold";
  font-weight: 600;
  font-style: normal;
  src: url(~assets/fonts/opensans/opensans_semibold.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans italic";
  font-weight: normal;
  font-style: italic;
  src: url(~assets/fonts/opensans/opensans_italic.ttf) format("truetype");
}

